import React, {Component} from 'react';
import {Graph} from './Graph';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {EditPage} from './EditPage';
import {GoogleLogin} from 'react-google-login';
import {ErrorPage} from './ErrorPage';
import {Forecast} from './Forecast';
import {ForecastGraph} from './ForecastGraph';
export class Viewable extends Component {
  constructor(props) {
    super(props);
    // urls are in the format name_id, so splitting by '_' and getting the last entry is guaranteed to be the id.
    // var temp = props.match.params.url.split('_');
    // var id = temp[temp.length-1];
    this.state = {
			chartURL: props.match.params.url,
			data: null, 
      options: null, 
      chartType: null, 
      redirect: null,
      errorMessage: null,
      projectName: '',
      userProfile: JSON.parse(localStorage.getItem('userProfile')) || null,
      isAuthenticated: null,
    };

    this.handleEditButton = this.handleEditButton.bind(this);
    this.handleFetchError = this.handleFetchError.bind(this);

    if (window.location.host.startsWith('localhost')) {
      this.baseURL = 'http://localhost:8080';
    } else if (window.location.host.startsWith('charts-testing')) {
      this.baseURL = 'https://web-charts-test.uc.r.appspot.com';
    } else {
      this.baseURL = 'https://web-charts.uc.r.appspot.com';
    }
  }

	onEditButtonLogin = (googleUser) =>{
		// this is fired when the google authentication is a success.
		const token = googleUser.getAuthResponse().id_token;

		const googleProfile = googleUser.getBasicProfile();
		const userProfile = {givenName: googleProfile.getGivenName(),
			familyName: googleProfile.getFamilyName(),
			email: googleProfile.getEmail(),
			imageUrl: googleProfile.getImageUrl(),
			token: token};
		this.setState({userProfile: userProfile});
		// once the userprofile is set, try to redirect to edit page again
		this.handleEditButton('');
	}


	handleEditButton(googleLogin) {
		// handles edit google button login.
		if (this.state.userProfile) {
			// if there's a user logged in, check if they have perms and handle appropriately.
			const formBody = new URLSearchParams();
			formBody.append('url', this.state.chartURL);
			formBody.append('token', this.state.userProfile.token);
			formBody.append('email', this.state.userProfile.email);

			const requestOptions = {
				method: 'POST',
				headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
				body: formBody,
			};

			// server checks if user has perms to edit.
			fetch(this.baseURL+'/verify-edit-perms', requestOptions)
					.then((res) => res.json())
					.then((results) => {
						if (results.verified===true) {
							localStorage.setItem('userProfile', JSON.stringify(this.state.userProfile));
							this.setState({redirect: '/edit'});
						} else {
							//alert('You are not authorized to edit this chart.');
							googleLogin.onClick();
							this.setState({awaitingLogin: true});
						}
					});
		} else {
			// if there is no one logged in, make them log in.
			googleLogin.onClick();
			this.setState({awaitingLogin: true});
		}
	}

	handleFetchError(err) {
		console.log(err.message);
		this.setState({
			redirect: '/error',
			errorMessage: err.message,
		});
	}

	onFailure = (error) => {
		console.log(error);
	}

	logout = () => {
		this.setState({
			redirect: null,
			isAuthenticated: false,
			userProfile: null,
		});
	};

	onSuccess = (googleUser) => {
		// this handles the google login if the chart is private
		const token = googleUser.getAuthResponse().id_token;
		const googleProfile = googleUser.getBasicProfile();
		const userProfile = {
			givenName: googleProfile.getGivenName(),
			familyName: googleProfile.getFamilyName(),
			email: googleProfile.getEmail(),
			imageUrl: googleProfile.getImageUrl(),
			token: token,
		};

		this.verifyViewer(userProfile);
	}

	verifyViewer = (userProfile) => {
		console.log(userProfile.givenName + ' ' + userProfile.familyName);
		console.log(userProfile.email);
		console.log(userProfile.imageUrl);
		console.log('ID Token: ' + userProfile.token);

		let formBody = new URLSearchParams();
		formBody.append('url', this.state.chartURL);
		formBody.append('token', userProfile.token);
		formBody.append('email', userProfile.email);

		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
			body: formBody,
		};

		fetch(this.baseURL+'/verify-viewer', requestOptions)
				.then((res) => res.json())
				.then((results) => {
					// if the user is verified, query for data
					if (results.verified) {
						// set the localstorage
						localStorage.setItem('userProfile', JSON.stringify(userProfile));

						formBody = new URLSearchParams();
						// need all of these, server will verify if the user is valid too
						formBody.append('url', this.state.chartURL);
						formBody.append('token', userProfile.token);
						formBody.append('email', userProfile.email);
						formBody.append('is_public', this.state.is_public);
						const newRequestOptions = {
							method: 'POST',
							headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
							body: formBody,
						};
						fetch(this.baseURL+'/get-chart-data-by-url', newRequestOptions)
								.then((res)=>res.json())
								.then((response)=>{
									this.setState({
										data: response.data,
										isAuthenticated: true,
										userProfile: userProfile,
									});
								}).catch((err) => this.handleFetchError(err));
					} else {
						this.setState({
							isAuthenticated: false,
							userProfile: userProfile,
						});
					}
				});
	}

	loadDisqusWidget() {
		// eslint-disable-next-line
		var disqus_config = function () {
			this.page.url = window.location.protocol+'://'+window.location.host + '/view-'+this.state.chartURL;
			this.page.identifier = this.state.chartURL;
			this.page.title = this.state.options ? this.state.options.title : this.state.chartURL;
		};

		(function() { // this is from the template provided by Disqus
			const d = document; const s = d.createElement('script');
			s.src = 'https://groveflow-web-charts.disqus.com/embed.js';
			s.setAttribute('data-timestamp', +new Date());
			(d.head || d.body).appendChild(s);
		})();
	}


	componentDidMount() {
		// after mounting, query the api to get the chart configuration
		const formBody = new URLSearchParams();
		formBody.append('url', this.state.chartURL);

		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
			body: formBody,
		};

		fetch(this.baseURL+'/get-chart-config-by-url', requestOptions)
				.then((res)=>res.json())
				.then((results)=>{
					try {
						// now have chart options + is_public => use to query for actual database
						this.setState({
							options: results.options,
							is_public: results.is_public,
							chartType: results.chartType,
						});

						if (results.is_public === 1) {
							// try to query only if the chart is public. otherwise, we only query after login authentication
							const formBody = new URLSearchParams();
							formBody.append('url', this.state.chartURL);
							formBody.append('is_public', this.state.is_public);
							const newRequestOptions = {
								method: 'POST',
								headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
								body: formBody,
							};
							fetch(this.baseURL+'/get-chart-data-by-url', newRequestOptions)
									.then((res)=>res.json())
									.then((results)=>{
										this.setState({
											data: results.data,
											isAuthenticated: true,
										});
									}).catch((err) => this.handleFetchError(err));
						} else if (this.state.userProfile !== null) {
							this.verifyViewer(this.state.userProfile);
						}
					} catch (err) {
						console.log(err);
					}
				}).catch((err) => this.handleFetchError(err));
	}

	render() {
		// console.log(disqusConfig);

		if (this.state.redirect === '/edit') {
			// if we want to edit, re-render with the editpage info instead.
			return <EditPage userProfile={this.state.userProfile} data={this.state.data} options={this.state.options} chartURL={this.state.chartURL} chartType={this.state.chartType}/>;
		} else if (this.state.redirect === '/error' && this.state.errorMessage) {
			return <ErrorPage {...this.props} userProfile={this.state.userProfile} message={this.state.errorMessage}/>;
		} else if (this.state.redirect) {
			throw new Error('Should never get here');
		}

		if (this.state.is_public === 0) {
			if (!this.state.userProfile) {
				// page is private and not logged in => display the option to login
				return (
					<div className="wrapper_centering" style={{fontFamily: 'Poppins'}}>
						<br/><br/>
						<div className="corner-ribbon top-right sticky red">Beta Testing</div>
						<div className="container_centering">
							<br/>
							<img src="img/logo.png" style={{width: 55, height: 55, marginLeft: 20}} alt="logo"/>
							<div className="container">
								<div className="row justify-content-between">

									<div className="main_title_1">
										<h3>GroveFlow Web Charts</h3>
										<p style={{fontSize: 26, fontWeight: 'bold'}}>
											This chart is private. Sign in with Google below for access. 
										</p>
									</div>

									<div className="col-xl-6 col-lg-6 align-items-center">
										<div className="main_title_1">

											<div className="sign-in-button-div" style={{marginTop: 40}}>
												<GoogleLogin
													clientId="926404384090-mu63593muenf42d8r0p3rvsk8sdk7gq6.apps.googleusercontent.com"
													buttonText="Sign In with Google"
													onSuccess={this.onSuccess}
													onFailure={this.onFailure}
												/>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>

					</div>
				);
			} else if (!this.state.isAuthenticated) {
				// page is private and they're not authorized => display no access
				return (
					<div className="wrapper_centering" style={{fontFamily: 'Poppins'}}>
						<br/><br/>
						<div className="corner-ribbon top-right sticky red">Beta Testing</div>
						<div className="container_centering">
							<br/>
							<img src="img/logo.png" style={{width: 55, height: 55, marginLeft: 20}} alt="logo"/>
							<div className="container">
								<div className="row justify-content-between">
									<div className="main_title_1">
										<h3>GroveFlow Web Charts</h3>
										<p style={{fontSize: 26, fontWeight: 'bold'}}>
											You do not have access to view this chart.
										</p>
										<p>
											<a style={{fontSize: 22, color: 'yellow'}} 
												 href={'/view-'+this.state.chartURL} 
												 onClick={localStorage.removeItem('userProfile')}>
												 Sign in with a different account
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>

					</div>
				);
			}
		}

		return (
			// otherwise, all good so display chart
			<div className="wrapper_centering" style={{fontFamily: 'Poppins'}}>
				<br/><br />
				<div className="corner-ribbon top-right sticky red">Beta Testing</div>

				<div className="container_centering">
					<br/>
					<img src="img/logo.png" style={{width: 55, height: 55, marginLeft: 20}} alt="logo"/>

					<div className="container">
						<div className="row justify-content-between">

							<div className="main_title_1">
								<h3>GroveFlow Web Charts</h3>
								<p style={{fontSize: 26, fontWeight: 'bold'}}>
									Send us your thoughts: <a style={{color: 'yellow'}} href={'mailto:hello@groveflow.com'}>hello@groveflow.com</a>
								</p>
							</div>


							<div>
								<div id="chart_div" className="live-chart-preview">
									{this.state.data ? <Graph data={this.state.data} options={this.state.options} chartType={this.state.chartType}/> : null}
								</div>
								{this.state.data ? <ForecastGraph options={this.state.options} chartType={this.state.chartType} userProfile={this.state.userProfile} chartURL={this.state.chartURL}/> : null}
							</div>

							<div>

								{/* available in private beta */}
								{this.state.data ? <Forecast data={this.state.data} chartURL={this.state.chartURL} options={this.state.options} chartType={this.state.chartType} userProfile={this.state.userProfile}/> : null}
								
								<div id="disqus_thread"></div>
								{ this.loadDisqusWidget() }

							</div>

							<div className="main_title_1">
								<br/><br/>
								<p style={{fontSize: 26}}> View and share this chart: <br />
									<a style={{fontSize: 26, color: 'yellow'}} href={'/view-'+this.state.chartURL}>charts.groveflow.com/view-{this.state.chartURL}</a> </p>

								<table style={{width: '120%', marginTop: '10px'}}><tr><td>

									<a style={{fontSize: 22, color: 'yellow'}} href="#Copy">
										<svg width="1.1em" height="1.1em" viewBox="0 0 16 16" className="bi bi-clipboard-data" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
											<path fillRule="evenodd" d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
											<path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z"/>
										</svg>
										<CopyToClipboard text={'charts.groveflow.com/view-'+this.state.chartURL}>
											<a href="#CopyLink" style={{color: 'yellow'}}> Copy Link </a>
										</CopyToClipboard>
									</a>
								</td><td>

									<a style={{fontSize: 22, color: 'yellow'}} href="#Edit">
										<GoogleLogin
											clientId="926404384090-mu63593muenf42d8r0p3rvsk8sdk7gq6.apps.googleusercontent.com"
											render={(renderProps) => (
												<span >
													<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
														<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
														<path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
													</svg>
													<span id = 'editText' onClick={()=>{
														this.handleEditButton(renderProps);
													}} > Edit This Chart</span>
												</span>
											)}
											buttonText="Login"
											onSuccess={this.onEditButtonLogin}
											onFailure={this.onFailure}

											// cookiePolicy={'single_host_origin'}
										/>
									</a>
								</td><td>
									<a style={{fontSize: 22, color: 'yellow'}} href="/">
										<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
											<path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
											<path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
										</svg>
										{' Create New Chart'}
									</a>


								</td></tr></table>

							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}
