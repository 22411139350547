import React, {Component} from 'react';
import {Chart} from 'react-google-charts';
import {ErrorPage} from './ErrorPage';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';

export class Graph extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    const data = props.data;
    // parse the data object to extract column objecs from the first entry of the data array
    // then extract all the row data.
    // this is to display as part of the react-data-grid module
    // console.log(data);
    const rows = [];
    for (let i = 0; i < data.length; i++) {
      const row = [];
      for (const col in data[i]) {
        row.push({value: data[i][col], width: 400});
      }
      rows.push(row);
    }
    const options = {...props.options};
    if (props.chartType==='BarChart') {
      console.log(options);
      let maxY = -1;
      for (const point in data) {
        if (data[point][1]>maxY) {
          maxY = data[point][1];
        }
      }
      options.hAxis.viewWindow.min = 0;
      options.hAxis.viewWindow.max = maxY;
      options.hAxis.title = data[0][1];
      options.vAxis.title = data[0][0];
    } else {
      options.hAxis.viewWindow = {};
      options.hAxis.viewWindow.min = 0;
      options.hAxis.viewWindow.max = Math.min(20, data.length-1);
    }
    // console.log(rows);
    // console.log(columns);
    // update state with options, data, chartType (for google charts), and rows, columns (for react-data-grid)
    this.state = {
      options: options,
      data: props.data,
      rows: rows,
      chartType: props.chartType? props.chartType : 'LineChart',
      autoplay: 'Autoplay Start',
      edit: props.edit? props.edit : false,
      chartURL: props.chartURL,
      redirect: null,
      errorMessage: null,
    };
    console.log(this.state);

    // bind button handlers
    this.changeChart = this.changeChart.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.prevButton = this.prevButton.bind(this);
    this.zoomInButton = this.zoomInButton.bind(this);
    this.zoomOutButton = this.zoomOutButton.bind(this);
    this.autoplayAssist = this.autoplayAssist.bind(this);
    this.handleAutoplayOnClick = this.handleAutoplayOnClick.bind(this);
    this.newRow = this.newRow.bind(this);
    this.updateDatabase = this.updateDatabase.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.handleRadioOnClick = this.handleRadioOnClick.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.onCellsChanged = this.onCellsChanged.bind(this);

    if (window.location.host.startsWith('localhost')) {
      this.baseURL = 'http://localhost:8080';
    } else if (window.location.host.startsWith('charts-testing')) {
      this.baseURL = 'https://web-charts-test.uc.r.appspot.com';
    } else {
      this.baseURL = 'https://web-charts.uc.r.appspot.com';
    }
  }

  downloadCSV() {
    // write the csv, then download it
    let csv='';
    this.state.data.forEach(function(row) {
      csv+=row.join(',');
      csv+='\n';
    });
    console.log(csv);
    const hiddenElement=document.createElement('a');
    hiddenElement.href='data:text/csv;charset=utf-8,'+encodeURI(csv);
    hiddenElement.target='_blank';
    hiddenElement.download=this.state.options.title+'.csv';
    hiddenElement.click();
  }

  updateDatabase() {
    // if datasheet incomplete, display warning and return
    if (this.state.warningMessage) {
      document.getElementById('sheet_warning').style.display = 'block';
      return;
    }

    // only update if the editor is present (edits are even possible)
    if (this.state.chartURL && this.state.edit) {
      // we do two api requests, one to update the charts_data with changes to data,
      // and the other to update charts with changes to the chart options
      const saveButton = document.getElementById('save_button');
      saveButton.disabled = true;
      saveButton.textContent='Saving... please wait';

      const formBody = new URLSearchParams();
      const optionsBody = new URLSearchParams();
      optionsBody.append('url', this.state.chartURL);
      optionsBody.append('options', JSON.stringify(this.state.options));
      optionsBody.append('chartType', this.state.chartType);
      formBody.append('url', this.state.chartURL);
      formBody.append('data', JSON.stringify(this.state.data.slice(1, this.state.data.length)));
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        body: formBody,
      };
      const secondRequestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        body: optionsBody,
      };
      // chart options will almost always complete first, so we only need to catch errors.
      fetch(this.baseURL+'/update-chart-options', secondRequestOptions).catch((err)=>{
        console.log(err.message);
        this.setState({
          redirect: '/error',
          errorMessage: err.message,
        });
      });

      // we disabled the button to click save while updating the database at the start,
      // on completion we update the text and enable the button.
      fetch(this.baseURL+'/update-chart', requestOptions).then((res)=>{
        saveButton.disabled = false;
        window.open('/view-'+this.state.chartURL, '_self');

      }).catch((err) => {
        console.log(err.message);
        this.setState({
          redirect: '/error',
          errorMessage: err.message,
        });
      });
    }
  }

  handleRadioOnClick() {
    // handles when the user changes the color by clicking a different circle.
    const radioButtons = document.getElementsByName('color');
    for (const button in radioButtons) {
      if (radioButtons[button].checked) {
        this.setState({
          options: {
            ...this.state.options,
            series: {
              0: {
                color: radioButtons[button].value,
              },
            },
          },
        });
      }
    }
  }

  newRow() {
    // add a new empty object to both the rows for react-data-grid and data for google-charts
    this.setState({
      rows: [...this.state.rows, [{value: ''}, {value: ''}]],
    });
  }

  prevButton() {
    // copy the same options object in the state, but now slightly modify the state.options.hAxis.viewWindow.min and max
    this.setState({
      options: {
        ...this.state.options,
        hAxis: {
          ...this.state.options.hAxis,
          viewWindow: {
            min: this.state.options.hAxis.viewWindow.min - 1,
            max: this.state.options.hAxis.viewWindow.max - 1,
          },
        },
      },
    });
  }

  nextButton() {
    // same as prev, just increase values instead of decrease
    this.setState({
      options: {
        ...this.state.options,
        hAxis: {
          ...this.state.options.hAxis,
          viewWindow: {
            min: this.state.options.hAxis.viewWindow.min + 1,
            max: this.state.options.hAxis.viewWindow.max + 1,
          },
        },
      },
    });
  }

  zoomInButton() {
    // same as both next and prev, just check if you can zoom in any further
    if (this.state.options.hAxis.viewWindow.min + 5 < this.state.options.hAxis.viewWindow.max - 5) {
      this.setState({
        options: {
          ...this.state.options,
          hAxis: {
            ...this.state.options.hAxis,
            viewWindow: {
              min: this.state.options.hAxis.viewWindow.min + 5,
              max: this.state.options.hAxis.viewWindow.max - 5,
            },
          },
        },
      });
    }
  }

  zoomOutButton() {
    // zoom out by adding 5 to max and subtracting 5 from min, just use conditional operator to check if the min goes below the first
    // element, as well as if the max goes beyond the max element (if so, you can't zoom out in that direction anymore!)
    this.setState({
      options: {
        ...this.state.options,
        hAxis: {
          ...this.state.options.hAxis,
          viewWindow: {
            min: this.state.options.hAxis.viewWindow.min - 5 > 0 ?
                                this.state.options.hAxis.viewWindow.min - 5 : 0,
            max: this.state.options.hAxis.viewWindow.max + 5 < this.state.data.length ?
                                this.state.options.hAxis.viewWindow.max + 5 : this.state.data.length,
          },
        },
      },
    });
  }

  autoplayAssist() {
    // method to assist with autoplay. is bound to editpage component so it can affect the state
    // continues to update the min/max increments to simulate the "moving" of the dataframe.
    if (this.state.options.hAxis.viewWindow.max + 2 >= this.state.data.length) {
      // if we hit the end, stop!
      window.clearInterval(this.state.save);
      this.setState({
        autoplay: 'Autoplay Start',
        save: undefined,
        options: {
          ...this.state.options,
          hAxis: {
            ...this.state.options.hAxis,
            viewWindow: {
              min: this.state.options.hAxis.viewWindow.min + 2,
              max: this.state.options.hAxis.viewWindow.max + 2,
            },
          },
        },
      });
    } else {
      this.setState({
        options: {
          ...this.state.options,
          hAxis: {
            ...this.state.options.hAxis,
            viewWindow: {
              min: this.state.options.hAxis.viewWindow.min + 2,
              max: this.state.options.hAxis.viewWindow.max + 2,
            },
          },
        },
      });
    }
  }

  handleAutoplayOnClick() {
    // the this.state.save object stores the id of the interval method being repeated.
    // if it exists in the state, then it means there's the moving going on, so clicking on the button needs to stop it.

    if (this.state.save) {
      // var tempMin = options.hAxis.viewWindow.min;
      // var tempMax = options.hAxis.viewWindow.max;
      window.clearInterval(this.state.save);
      this.setState({save: undefined, autoplay: 'Autoplay Start'});
      // options.hAxis.viewWindow.min = tempMin;
      // options.hAxis.viewWindow.max = tempMax;
      // drawChart();
    } else {
      // if not currently going, start it!
      this.setState({
        save: window.setInterval(this.autoplayAssist, 1000),
        autoplay: 'Autoplay Pause'});
      // console.log(save);
    }
  }

  updateTitle() {
    // updates the chart title with whatever is in the input field
    const newName = document.getElementById('chart_name');
    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        title: newName.value,
      },
    }));
  }

  changeChart() {
    // change the chart type to whatever is selected
    const newType = document.getElementById('chart_type_select').value;
    const currentType = this.state.chartType;
    console.log(currentType);
    if (newType==='BarChart') {
      // if its a bar chart, we need to switch the axes.
      let maxY = -1;
      for (const point in this.state.data) {
        if (this.state.data[point][1]>maxY) {
          maxY = this.state.data[point];
        }
      }
      this.setState({
        options: {
          ...this.state.options,
          hAxis: {
            ...this.state.options.hAxis,
            title: this.state.data[0][1],
            viewWindow: {
              min: 0,
              max: maxY,
            },
          },
          vAxis: {
            ...this.state.options.vAxis,
            title: this.state.data[0][0],
          },
        },
        chartType: newType,
      });
    } else {
      // if its currently a bar chart, we need to unswitch the axes.
      if (currentType==='BarChart') {
        this.setState({
          chartType: newType,
          options: {
            ...this.state.options,
            hAxis: {
              ...this.state.options.hAxis,
              title: this.state.data[0][0],
              viewWindow: {
                min: 0,
                max: Math.min(20, this.state.data.length-1),
              },
            },
            vAxis: {
              ...this.state.options.vAxis,
              title: this.state.data[0][1],
            },
          },
        });
      } else {
        // otherwise, we just switch type
        this.setState({chartType: newType});
      }
    }
  }

  onCellsChanged(changes) {
    // remove warning message while editing
    document.getElementById('sheet_warning').style.display = 'none';

    const grid = this.state.rows.map((row) => [...row]);

    let newHTitle = this.state.options.hAxis.title;
    let newVTitle = this.state.options.vAxis.title;

    changes.forEach(({cell, row, col, value}) => {
      grid[row][col] = {...grid[row][col], value};

      // x and y axes are switched in bar chart
      if (row === 0) {
        if (this.state.chartType === 'BarChart') {
          if (col === 0) {
            newVTitle = value;
          } else {
            newHTitle = value;
          }
        } else {
          if (col === 0) {
            newHTitle = value;
          } else {
            newVTitle = value;
          }
        }
      }
    });

    // map nonempty rows to arrays to use in chart
    const newData = grid.slice(1).filter((row) => {
      return row[0].value !== '' && row[1].value !== '';
    }).map((row) => {
      return [row[0].value, parseFloat(row[1].value)];
    });
    newData.unshift([grid[0][0].value, grid[0][1].value]);

    // default no warning
    let warningMessage = null;

    // x or y axis name is missing
    if (grid[0][0].value === '' || grid[0][1].value === '') {
      console.log('axis name missing');
      warningMessage = 'Please enter names for both x and y axes';
    }

    // datasheet is empty
    if (grid.slice(1).filter((row) => {
      return row[0].value !== '' || row[1].value !== '';
    }).length === 0) {
      console.log('datasheet empty');
      warningMessage = 'Please enter data to submit';
    }

    // datasheet has uneven rows
    if (grid.slice(1).filter((row) => {
      // logical xor
      return row[0].value === '' ? row[1].value !== '' : row[1].value === '';
    }).length > 0) {
      console.log('uneven datasheet rows');
      warningMessage = 'Please enter both x and y values for each row';
    }

    this.setState((prevState) => ({
      rows: grid,
      data: newData,
      options: {
        ...prevState.options,
        hAxis: {
          ...prevState.hAxis,
          title: newHTitle,
        },
        vAxis: {
          ...prevState.hAxis,
          title: newVTitle,
        },
      },
      warningMessage: warningMessage,
    }));
  }

  render() {
    if (this.state.redirect === '/error' && this.state.errorMessage) {
      return <ErrorPage {...this.props} message={this.state.errorMessage}/>;
    } else if (this.state.redirect) {
      throw new Error('Should never get here');
    }

    //console.log(this.state.rows);
    //console.log("color: " + this.state.options.series[0].color);
    
    return (
      <div>
        <div className="main_title_1">
          <select name='chart_type_select' defaultValue ={this.state.chartType} id='chart_type_select' onChange={this.changeChart}>
            <option value='LineChart'>Line Chart</option>
            <option value='BarChart'>Bar Chart</option>
            <option value='ScatterChart'>Scatter Chart</option>
            <option value='AreaChart'>Area Chart</option>
          </select>
          <button onClick={this.prevButton}>Prev</button>
          <button onClick={this.nextButton}>Next</button>
          <button onClick={this.zoomInButton}>Zoom In</button>
          <button onClick={this.zoomOutButton}>Zoom Out</button>
          <button onClick={this.handleAutoplayOnClick}>{this.state.autoplay}</button>
          <button onClick={this.downloadCSV}>Download CSV</button>

          <div style={{marginTop: '10px'}}>
            <Chart id='chart' options={this.state.options} data={this.state.data} chartType={this.state.chartType}/>
          </div>

        </div>

        <div style={{display: this.state.edit ? true : 'none'}}>

          <ReactDataSheet
            data = {this.state.rows}
            valueRenderer={(cell)=>cell.value}
            onCellsChanged={this.onCellsChanged}
          />

          <table style={{width: '800px', marginTop: '-6px'}}>
            <tr>
              <td>
                <button onClick={this.newRow}>Add Row</button>
              </td>
              <td style={{textAlign: 'right'}}>
                <div style={{marginTop: '10px'}} className="form-group">
                  <label>Chart Name:</label>&nbsp;
                  <input type="text" onInput={this.updateTitle} id="chart_name" placeholder={this.state.options.title}/>
                </div>
              </td>
              <td style={{textAlign: 'right'}}>
                <div className="form-group radio_input">
                  <label>Line Color:</label>
                  <label className="container_radio">Red
                    <input type="radio" id="color_choice_1" name="color" value="#dc3912" 
                      checked={this.state.options.series[0].color === "#dc3912"}
                      onChange={this.handleRadioOnClick} required />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container_radio">Green
                    <input type="radio" id="color_choice_2" name="color" value="#109618" 
                      checked={this.state.options.series[0].color === "#109618"}
                      onChange={this.handleRadioOnClick} required />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container_radio">Blue
                    <input type="radio" id="color_choice_3" name="color" value="#007bff" 
                      checked={this.state.options.series[0].color === "#007bff"}
                      onChange={this.handleRadioOnClick} required />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </td>
            </tr>
          </table>

          <p id="sheet_warning" style={{color: 'red', display: 'none'}}>{this.state.warningMessage}</p>

          <div id="bottom-wizard">
            <button id="submit_button" type="submit" className="submit" 
                onClick={this.updateDatabase} id='save_button'>
                Save and View Chart
            </button>
          </div>


        </div>
      </div>
    );
  }
}
