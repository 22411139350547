import React, {Component} from 'react';
import {Graph} from './Graph';

export class Forecast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data, 
      options: props.options, 
      chartType: props.chartType, 
      userProfile: props.userProfile, 
      chartURL: props.chartURL,
      projectName: this.generateProjectName(props.chartURL),
    };
    

    //this.handleChange = this.handleChange.bind(this);
    this.handleForecast = this.handleForecast.bind(this);
    this.getForecast = this.getForecast.bind(this);

    if (window.location.host.startsWith('localhost')) {
      this.baseURL = 'http://localhost:8080';
    } else if (window.location.host.startsWith('charts-testing')) {
      this.baseURL = 'https://web-charts-test.uc.r.appspot.com';
    } else {
      this.baseURL = 'https://web-charts.uc.r.appspot.com';
    }
  }


  generateProjectName(chartURL) {
    // projectName will cause AWS errors if hyphens
    // also length < 63 total w suffixes (so going to length ~20 here)

    chartURL = chartURL.replace(/-/g, '_');

    const headString = chartURL.substring(0, 14);
    const tailString = chartURL.substring(chartURL.length-6, chartURL.length);

    return headString + tailString; 
  }


  checkBetaAuth() {
    const formBody = new URLSearchParams();
    formBody.append('email', this.state.userProfile.email);
    formBody.append('token', this.state.userProfile.token);
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: formBody,
    };
    fetch(this.baseURL+'/check-beta-auth', requestOptions)
        .then((res)=>res.json())
        .then((results)=>{
          // console.log(results);
          this.setState({beta: results.beta});
        });
  }

  componentDidMount() {
    this.checkBetaAuth();
  }

  /*
  handleChange(event) {
    this.setState({projectName: event.target.value});
  }
  */

  handleForecast(event) {
    event.preventDefault();

    const forecastButton = document.getElementById('start_button');
    forecastButton.disabled = true;
    forecastButton.innerHTML = 'Forecast started!\n\nWe\'ll email you when it\'s completed.';
    const formBody = new URLSearchParams();
    formBody.append('url', this.state.chartURL);
    formBody.append('projectName', this.state.projectName);
    console.log('Forecast project name: ' + this.state.projectName);

    formBody.append('horizon', Math.floor(this.state.data.length/4));
    formBody.append('interval', document.getElementById('interval').value);
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: formBody,
    };
    fetch(this.baseURL+'/initiate-forecast', requestOptions)
        .then((res)=>res.json())
        .then((results)=>{
          console.log(results);
        });
  }

  getForecast(event) {
    event.preventDefault();
    const formBody = new URLSearchParams();
    formBody.append('url', this.state.chartURL);
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: formBody,
    };
    fetch(this.baseURL+'/get-forecast-results', requestOptions)
        .then((res)=>res.json())
        .then((results)=>{
          console.log(results);
          if (!results.error) {
            this.setState({'p10': results.p10, 'p50': results.p50, 'p90': results.p90});
          } else {
            alert('Forecast Incomplete');
          }
        });
  }

  render() {
    const p10options ={...this.state.options};
    const p50options = {...this.state.options};
    const p90options={...this.state.options};
    p10options.title = this.state.options.title+' 10th percentile forecast';
    p50options.title = this.state.options.title+' 50th percentile forecast';
    p90options.title = this.state.options.title+' 90th percentile forecast';
    
    if (!this.state.beta) {
      return null; // user not in private beta, or not authenticated
    } else {
      return (
        <div style={{width: '300px', background: 'transparent', marginBottom: '20px', border: '2px dotted yellow', padding: '2em'}}>
          
          {/*
          <p style={{color: 'white'}}>
                      Instructions for use:<br></br>
                      1. Make sure the y-axis name matches the item you want to forecast in your csv.<br></br>
                      2. Type in a project name, without any spaces, and click submit!<br></br>
                      3. Wait for the completion email, then view the results.
          </p>
          */}
          <form onSubmit={this.handleForecast}>
            {/* <input type='text' value={this.state.projectName} onChange={this.handleChange}/> */}

            <div className="main_title_1" style={{fontWeight: '700'}}>
              <span style={{fontSize: '18px', color: 'yellow', textDecoration: 'none'}}>Exclusive Private Beta:</span><br></br>
              <span style={{fontSize: '18px', color: 'white'}}>Forecast the Future with Machine Learning</span>
            </div>

            <br></br>
            <select id="interval" name="interval" required
              style={{color: 'white', fontSize: 17, backgroundColor: 'transparent', borderStyle: 'dotted', borderColor: 'lightgray'}}>

              <option value="">- Select Data Interval -</option>
              <option value="Y">Years</option>
              <option value="M">Months</option>
              <option value="W">Weeks</option>
              <option value="D">Days</option>
              <option value="H">Hours</option>
              <option value="M">Minutes</option>
            </select> <br></br><br></br>
            <button id="start_button" className="submit" type="submit">
              Start Forecasting
            </button>
            <br></br><br></br>

            <div style={{color: 'white'}}>Predictions complete in ~1 hour.</div>
          </form>

        </div>
      );
    }
  }
}
