import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Main} from './components/Main';
import {Viewable} from './components/Viewable';
import './App.css';

class App extends Component {

  render() {
    return (
      <Router>
        <Switch> {/* renders first route that matches url */}
          <Route exact path="/" render={(props) => (
            <div>
              <br /><br />
              <Main> </Main>
            </div>
          )} />

          {/* <Route exact path="/setup" render={(props) => <Setup {...props}/>} />
          <Route exact path="/populate"><Populate /></Route>
          <Route exact path="/finish"><Finish /></Route>
          <Route exact path = "/edit"><EditPage /></Route> */}
          <Route exact path="/view-:url" component={Viewable}/>

        </Switch>
      </Router>
    );
  }
}

export default App;
