import React, {Component} from 'react';
import {GoogleLogin} from 'react-google-login';
import {Setup} from './Setup';

export class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {redirect: null, isAuthenticated: false, userProfile: null};
    this.handleStartButton = this.handleStartButton.bind(this);
  }

  handleStartButton(googleLogin) {
    googleLogin.onClick();
  }

	logout = () => {
		this.setState({redirect: null, isAuthenticated: false, userProfile: null});
	};

	onSuccess = (googleUser) => {
		const token = googleUser.getAuthResponse().id_token;

		const googleProfile = googleUser.getBasicProfile();
		const userProfile = {givenName: googleProfile.getGivenName(),
			familyName: googleProfile.getFamilyName(),
			email: googleProfile.getEmail(),
			imageUrl: googleProfile.getImageUrl(),
			token: token};

		console.log(userProfile.givenName + ' ' + userProfile.familyName);
		console.log(userProfile.email);
		console.log(userProfile.imageUrl);
		console.log('ID Token: ' + userProfile.token);

		if (userProfile.email) {
			this.setState({redirect: '/setup', isAuthenticated: true, userProfile: userProfile});
		} else {
			this.setState({redirect: '', isAuthenticated: false, userProfile: null});
		}
	}

	onFailure = (error) => {
		console.log(error);
	}


	render() {
		// after logging in...
		if (this.state.isAuthenticated && this.state.redirect) {
			return <Setup userProfile={this.state.userProfile} />;
		}

		// before logging in...
		return (

			<div className="wrapper_centering" style={{fontFamily: 'Poppins'}}>
				<br/><br />
				<div className="corner-ribbon top-right sticky red">Beta Testing</div>

				<div className="container_centering">
					<img src="img/logo.png" style={{width: 55, height: 55, marginLeft: 40}} alt="logo"/>

					<div className="container">
						<div className="row">

							<div className="col-xl-6 col-lg-6 align-items-center">
								<div className="main_title_1" style={{marginLeft: 50}}>

									<h3 style={{fontSize: 62}}>GroveFlow <br /> Web Charts </h3> <br />

									<p style={{fontSize: 26, fontWeight: 'bold'}}>
										<svg style={{marginLeft: 10, marginRight: 10, marginBottom: 5}} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-graph-up" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path d="M0 0h1v16H0V0zm1 15h15v1H1v-1z"/>
											<path fillRule="evenodd" d="M14.39 4.312L10.041 9.75 7 6.707l-3.646 3.647-.708-.708L7 5.293 9.959 8.25l3.65-4.563.781.624z"/>
											<path fillRule="evenodd" d="M10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4h-3.5a.5.5 0 0 1-.5-.5z"/>
										</svg>
										{' Create your own charts.'}
									</p>

									<p style={{fontSize: 26, fontWeight: 'bold'}}>
										<svg style={{marginLeft: 10, marginRight: 10, marginBottom: 5}} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-globe" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" d="M1.018 7.5h2.49c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5zM2.255 4H4.09a9.266 9.266 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.024 7.024 0 0 0 2.255 4zM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm-.5 1.077c-.67.204-1.335.82-1.887 1.855-.173.324-.33.682-.468 1.068H7.5V1.077zM7.5 5H4.847a12.5 12.5 0 0 0-.338 2.5H7.5V5zm1 2.5V5h2.653c.187.765.306 1.608.338 2.5H8.5zm-1 1H4.51a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm1 2.5V8.5h2.99a12.495 12.495 0 0 1-.337 2.5H8.5zm-1 1H5.145c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12zm-2.173 2.472a6.695 6.695 0 0 1-.597-.933A9.267 9.267 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM1.674 11H3.82a13.651 13.651 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm8.999 3.472A7.024 7.024 0 0 0 13.745 12h-1.834a9.278 9.278 0 0 1-.641 1.539 6.688 6.688 0 0 1-.597.933zM10.855 12H8.5v2.923c.67-.204 1.335-.82 1.887-1.855A7.98 7.98 0 0 0 10.855 12zm1.325-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm.312-3.5h2.49a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.91 4a9.277 9.277 0 0 0-.64-1.539 6.692 6.692 0 0 0-.597-.933A7.024 7.024 0 0 1 13.745 4h-1.834zm-1.055 0H8.5V1.077c.67.204 1.335.82 1.887 1.855.173.324.33.682.468 1.068z"/>
										</svg>
										{'  Share and discuss your data.'}
									</p>

									<p style={{fontSize: 26, fontWeight: 'bold'}}>
										<svg style={{marginLeft: 10, marginRight: 10, marginBottom: 5}} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-calendar-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
											<path fillRule="evenodd" d="M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-3a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
											<path fillule="evenodd" d="M3.5 0a.5.5 0 0 1 .5.5V1a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 .5-.5zm9 0a.5.5 0 0 1 .5.5V1a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 .5-.5z"/>
										</svg>
										{' Fast and no coding required.'}
									</p>
								</div>
							</div>

							<div className="col-xl-6 col-lg-6 align-items-center">
								<div className="main_title_1">
									<div className="App">
										<div className="sign-in-button-div" style={{marginTop: 20, marginLeft: 60}}>
											<GoogleLogin
												clientId="926404384090-mu63593muenf42d8r0p3rvsk8sdk7gq6.apps.googleusercontent.com"
												render={(renderProps) => (
													<div>
														<a href="#Start" onClick={()=>{
															this.handleStartButton(renderProps);
														}} >
															<img src="img/chart_example.png" style={{width: 500}} className="example-chart-image" alt="chart example"/>

															<img src="img/goog_button_example.png" style={{marginLeft: 50, marginTop: 20}} alt="Sign In with Google"></img>
														</a>
													</div>
												)}
												buttonText="Sign In with Google"
												onSuccess={this.onSuccess}
												onFailure={this.onFailure}
											/>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}
