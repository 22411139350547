import React, {Component} from 'react';

export class ErrorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
    };

    this.returnToHomepage = this.returnToHomepage.bind(this);
  }

  returnToHomepage() {
    window.open('/', '_self');
  }

  render() {
    return (
      <div style={{fontFamily: 'Poppins'}}>
        <div className="wrapper_centering">
          <br/><br/>
          <div className="corner-ribbon top-right sticky red">Beta Testing</div>

          <div className="container_centering">
            <br/>
            <img src="img/logo.png" style={{width: 55, height: 55, marginLeft: 20}} alt="logo"/>

            <div className="container">
              <div className="row justify-content-between">
                <div className="main_title_1">
                  <p style={{fontSize: 30, fontWeight: 'bold'}}> Oops, something went wrong <span role="img" aria-label="emoji">&#128543;</span> </p>
                  <p style={{fontSize: 24}}> We're sorry about that. If this keeps happening,
                                        please contact us at <a style={{color: 'yellow'}} href={'mailto:eng@groveflow.com'}>eng@groveflow.com</a>.
                  </p>
                  <p>Error: {this.props.message}</p> <br /><br />
                  {/* <p><a style={{fontWeight: 'bold'}} href="../#Start" onClick={this.returnToHomepage}>Back to home &larr;</a></p>*/}
                  <button id= "submit_button" type="submit" onClick={this.returnToHomepage} className="submit">Back to home &larr;</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
