import React, {Component} from 'react';
import {Finish} from './Finish';
import {PrevCharts} from './PrevCharts';
import {ErrorPage} from './ErrorPage';
import ReactDataSheet from 'react-datasheet';

export class Populate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userProfile: JSON.parse(localStorage.getItem('userProfile')) || null,
      redirect: null,
      selectInput: 'public',
      limitedEmails: '',
      errorMessage: null,
      grid: [
        [
          {
            readOnly: true,
            value: this.props.options.title,
            colSpan: 2,
            width: 400,
          },
        ],
        [
          {
            readOnly: true,
            value: this.props.options.hAxis.title,
            width: 200,
          },
          {
            readOnly: true,
            value: this.props.options.vAxis.title,
            width: 200,
          },
        ],
      ],
      warningMessage: props.method === 'datasheet' ? 'Please enter data to submit' : null,
    };

    // create some empty rows to start
    for (let i = 0; i < 8; i++) {
      this.state.grid.push([{value: ''}, {value: ''}]);
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.handleSelectInputs = this.handleSelectInputs.bind(this);
    this.toggleVisibilityOptions = this.toggleVisibilityOptions.bind(this);
    this.addRow = this.addRow.bind(this);
    this.onCellsChanged = this.onCellsChanged.bind(this);

    if (window.location.host.startsWith('localhost')) {
      this.baseURL = 'http://localhost:8080';
    } else if (window.location.host.startsWith('charts-testing')) {
      this.baseURL = 'https://web-charts-test.uc.r.appspot.com';
    } else {
      this.baseURL = 'https://web-charts.uc.r.appspot.com';
    }

    this.userProfile = { // default test profile
      givenName: 'Test',
      familyName: 'User',
      email: 'test@user.com',
      imageUrl: '',
      token: '',
    };

    if (this.props.userProfile !== null) {
      this.state.userProfile = this.props.userProfile;
      localStorage.setItem('userProfile', JSON.stringify(this.props.userProfile));
      this.userProfile = this.props.userProfile;
    } else if (this.state.userProfile !== null) {
      this.userProfile = this.state.userProfile;
    } else {
      this.state.userProfile = this.userProfile;
    }

    console.log('this.userProfile.token:', this.userProfile.token);
  }

  async onSubmit(event) {
    event.preventDefault();
    // console.log("submitting populate form");

    // if datasheet incomplete, display warning and return
    if (this.state.warningMessage) {
      document.getElementById('sheet_warning').style.display = 'block';
      return;
    }

    // prevent double form submission
    document.getElementById('submit_button').disabled = true;

    const formData = new FormData(event.target);
    // for (var [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

    const response = await fetch(event.target.action, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      return res.json();
    }).catch((err) => {
      console.log(err.message);
      this.setState({
        redirect: '/error',
        errorMessage: err.message,
      });
      return null;
    });

    // console.log(response);
    if (response !== null) {
      this.setState({
        redirect: '/finish',
        data: response.data,
      });
    }
  }

  toggleVisibilityOptions() {
    const fileInput = document.getElementById('csv_file');

    if (fileInput !== null) {
      if (fileInput.files.length > 0) {
        document.getElementById('visibility_div').style.display = 'block';
      }
    }
  }

  addRow() {
    this.setState((prevState) => ({
      grid: [...prevState.grid, [{value: ''}, {value: ''}]],
    }));
  }

  onCellsChanged(changes) {
    // remove warning message while editing
    document.getElementById('sheet_warning').style.display = 'none';

    // copy grid to not mutate state
    const grid = this.state.grid.map((row) => [...row]);
    changes.forEach(({cell, row, col, value}) => {
      grid[row][col] = {...grid[row][col], value: value};
    });

    // map nonempty rows to objects to convert to json later
    const sheetData = grid.slice(2).filter((row) => {
      return row[0].value !== '' && row[1].value !== '';
    }).map((row) => ({
      x_val: row[0].value,
      y_val: row[1].value,
    }));

    // default no warning
    let warningMessage = null;

    // datasheet is empty
    if (grid.slice(2).filter((row) => {
      return row[0].value !== '' || row[1].value !== '';
    }).length === 0) {
      console.log('datasheet empty');
      warningMessage = 'Please enter data to submit';
    }

    // datasheet has uneven rows
    if (grid.slice(2).filter((row) => {
      // logical xor
      return row[0].value === '' ? row[1].value !== '' : row[1].value === '';
    }).length > 0) {
      console.log('uneven datasheet rows');
      warningMessage = 'Please enter both x and y values for each row';
    }

    this.setState({
      grid: grid,
      sheetData: sheetData,
      warningMessage: warningMessage,
    });
  }

  handleSelectInputs(event) {
    this.setState({
      selectInput: event.currentTarget.value,
    }, () => {
      let preFill = this.state.limitedEmails;

      if (this.state.selectInput === 'limited') {
        if (preFill === '') {
          preFill = 'example@gmail.com, example2@gmail.com, example3@gmail.com';
        }
        const enteredEmails = prompt('Please enter comma-separated email addresses that can view this data:', preFill);

        this.setState({limitedEmails: enteredEmails + ', ' + this.state.userProfile.email});
      } else if (this.state.selectInput ==='private') {
        this.setState({limitedEmails: this.state.userProfile.email});
      }
    });
  }


  render() {
    if (this.state.redirect === '/finish' && this.state.data) {
      return <Finish data={this.state.data} chartURL={this.props.chartURL} options={this.props.options} chartType={this.props.chartType}/>;
    } else if (this.state.redirect === '/error' && this.state.errorMessage) {
      return <ErrorPage {...this.props} message={this.state.errorMessage}/>;
    } else if (this.state.redirect) {
      throw new Error('Should never get here');
    }

    const formActionLink = this.props.method === 'csv' ? this.baseURL + '/populate-from-csv' :
                                                       this.baseURL + '/populate-from-datasheet';

    return (
      <div style={{fontFamily: 'Poppins'}}>
        <div className="wrapper_centering">
          <br/><br />
          <div className="corner-ribbon top-right sticky red">Beta Testing</div>

          <div className="container_centering">
            <br />
            <img src="img/logo.png" style={{width: 55, height: 55, marginLeft: 20}} alt="logo"/>

            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-6 col-lg-6 align-items-center">
                  <div className="main_title_1">

                    <p style={{fontSize: 30, fontWeight: 'bold'}}> Start with Simple Time Series </p>
                    <p style={{fontSize: 26}}> A GroveFlow Web Chart</p>
                    <img src="img/chart_example.png" style={{width: 500}} className="example-chart-image" alt="chart example"/> <br /><br />
                    {/*
                  // hide link to avoid the current chart showing up (and avoid user confusion)
                   <a className="test-link" data-toggle="modal" data-target="#previous-charts" style={{ color: 'yellow', fontSize: 18}}
                href="#ShowPrevious" > Show your previously-created charts </a>  */}

                  </div>
                </div>

                <div className="col-xl-5 col-lg-5 align-items-center">
                  <br /><br /><br />  <br /><br /><br />
                  <form id="form" action={formActionLink} method="post" onSubmit={this.onSubmit} autoComplete="off" encType="multipart/form-data">

                    <input readOnly id="chart_id" name="chart_id" type="hidden" value={this.props.chartId}/>
                    <input readOnly id="x_axis" name="x_axis" type="hidden" value={this.props.options.hAxis.title}/>
                    <input readOnly id="y_axis" name="y_axis" type="hidden" value={this.props.options.vAxis.title}/>

                    <h3 className="main_question"><b>Populate your chart, {this.state.userProfile.givenName}!</b></h3>

                    <div id="csv_container" style={{display: this.props.method === 'csv' ? 'block' : 'none'}}>
                      <h3 className="main_question">{'Download this '}
                        <a className="test-link" style={{color: 'yellow', textDecoration: 'underline'}}
                          href="csv/simple_time_series_template.csv" download>CSV file template</a>
                        {' to set up your data. Then upload your CSV here:'}
                      </h3>

                      <input type="file" onChange={this.toggleVisibilityOptions} style={{fontSize: 16}} accept=".csv" name="csv_file" id="csv_file" required={this.props.method === 'csv'}/>
                    </div>

                    <div id="datasheet_container" style={{display: this.props.method === 'datasheet' ? 'block' : 'none'}}>
                      <h3 className="main_question">Use this data sheet to submit your data:</h3>

                      <ReactDataSheet
                        data = {this.state.grid}
                        valueRenderer = {(cell) => cell.value}
                        onCellsChanged = {this.onCellsChanged}
                      />

                      <button id="add_button" type="button" onClick={this.addRow}>Add row</button>
                      <br/><br/>

                      <p id="sheet_warning" style={{color: 'red', display: 'none'}}>{this.state.warningMessage}</p>

                      <input type="hidden" id="sheet_data" name="sheet_data" value={JSON.stringify(this.state.sheetData)}/>
                    </div>

                    <div name="visibility_div" id="visibility_div" style={{display: this.props.method === 'csv' ? 'none' : 'block'}}>
                      <input type="hidden" id="visibility" name="visibility" value={this.state.selectInput}/>
                      <input type="hidden" id="limited_emails" name="limited_emails" value={this.state.limitedEmails} />

                      <br/>

                      <select name="visibility_select" id="visibility_select" value={this.state.selectInput} onChange={this.handleSelectInputs} style={{color: 'white', fontSize: 14, backgroundColor: 'transparent', borderStyle: 'dotted', borderCOlor: 'lightgray'}}>
                        <option value="public" style={{backgroundColor: '#1abc9c'}}>This data can be viewed by anyone</option>
                        <option value="limited" style={{backgroundColor: '#1abc9c'}}>This data is restricted to limited email addresses</option>
                        <option value="private" style={{backgroundColor: '#1abc9c'}}>This data is restricted only to me</option>
                      </select>
                    </div>

                    <div id="bottom-wizard">
                      <button id="submit_button" type="submit" className="submit">Create Chart</button>
                    </div>

                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
        <PrevCharts {...this.props}/>
      </div>
    );
  }
}
