import React, {Component} from 'react';
import {Populate} from './Populate';
import {Finish} from './Finish';
import {PrevCharts} from './PrevCharts';
import {ErrorPage} from './ErrorPage';

export class Setup extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props.userProfile);
    // console.log(localStorage)

    this.state = {
      // userProfile: JSON.parse(localStorage).userProfile || null,
      redirect: null,
      chartId: null,
      chartMap: null,
      errorMessage: null,
      submitText: 'Populate Chart',
    };

    if (localStorage.getItem('userProfile') === undefined || localStorage.getItem('userProfile') === 'undefined') {
      this.state.userProfile = null;
    } else {
      this.state.userProfile = JSON.parse(localStorage.getItem('userProfile'));
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onTemplateChange = this.onTemplateChange.bind(this);

    if (window.location.host.startsWith('localhost')) {
      this.baseURL = 'http://localhost:8080';
    } else if (window.location.host.startsWith('charts-testing')) {
      this.baseURL = 'https://web-charts-test.uc.r.appspot.com';
    } else {
      this.baseURL = 'https://web-charts.uc.r.appspot.com';
    }

    this.userProfile = { // default test profile
      givenName: 'Test',
      familyName: 'User',
      email: 'test@user.com',
      imageUrl: '',
      token: '',
    };

    if (this.props.userProfile !== null) {
      this.state.userProfile = this.props.userProfile;
      localStorage.setItem('userProfile', JSON.stringify(this.props.userProfile));
      this.userProfile = this.props.userProfile;
    } else if (this.state.userProfile !== null) {
      this.userProfile = this.state.userProfile;
    } else {
      this.state.userProfile = this.userProfile;
    }

    console.log('this.userProfile.token:', this.userProfile.token);
  }

  componentDidMount() {
    fetch(this.baseURL + '/get-charts-by-email', {
      method: 'POST',
      body: new URLSearchParams({
        'email': this.state.userProfile.email,
        'token': this.state.userProfile.token,
      }),
    }).then((res) => {
      return res.json();
    }).then((body) => {
      this.setState({chartMap: JSON.stringify(body.charts)});
    }).catch((err) => {
      console.log(err.message);
      this.setState({
        redirect: '/error',
        errorMessage: err.message,
      });
    });
  }

  async onSubmit(event) {
    event.preventDefault();
    // console.log("submitting setup form");

    // prevent double form submission
    document.getElementById('submit_button').disabled = true;

    const chartInfo = await fetch(event.target.action, {
      method: 'POST',
      body: new URLSearchParams(new FormData(event.target)),
    }).then((res) => {
      return res.json();
    }).catch((err) => {
      console.log(err.message);
      this.setState({
        redirect: '/error',
        errorMessage: err.message,
      });
      return null;
    });

    // console.log(chartInfo);
    if (chartInfo === null) {
      return;
    }

    this.setState({
      chartId: chartInfo.id,
      chartURL: chartInfo.url,
      chartType: 'LineChart',
      options: {
        legend: 'none',
        title: chartInfo.chart_name,
        chartArea: {
          width: '80%',
        },
        width: 800,
        height: 480,
        series: {
          0: {color: chartInfo.color}, /* red */
        },
        pointSize: 10,
        hAxis: {title: chartInfo.x_axis},
        vAxis: {title: chartInfo.y_axis},
        animation: {
          duration: 800,
          easing: 'in',
        },
      },
    });

    if (chartInfo.template === 'csv' || chartInfo.template === 'datasheet') {
      this.setState({
        redirect: '/populate',
        method: chartInfo.template,
      });
    } else {
      // console.log("template name:", chartInfo.template);
      const response = await fetch(this.baseURL + '/populate-from-template', {
        method: 'POST',
        body: new URLSearchParams({
          chart_id: chartInfo.id,
          template: chartInfo.template,
        }),
      }).then((res) => {
        return res.json();
      }).catch((err) => {
        console.log(err.message);
        this.setState({
          redirect: '/error',
          errorMessage: err.message,
        });
        return null;
      });

      // console.log(response);
      if (response !== null) {
        this.setState({
          redirect: '/finish',
          data: response.data,
        });
      }
    }
  }

  onTemplateChange(event) {
    // object that maps template names to chart name and x/y axis names
    const templates = {
      template_life: {
        chart_name: 'Healthy Life Expectancy at Birth',
        x_axis: 'Birth Year',
        y_axis: 'Global Average Years of Healthy Life',
      },
      template_gdp: {
        chart_name: 'Economic Output Over Time',
        x_axis: 'Year',
        y_axis: 'Global GDP Per Capita (current US$)',
      },
      template_temperature: {
        chart_name: 'Temperature Change Over Time',
        x_axis: 'Year',
        y_axis: 'Global Temperature Change since 2000 (in F)',
      },
    };

    // pre-populate fields of form
    const templateName = event.target.value;

    if (templateName === 'csv' || templateName === 'datasheet') {
      this.setState({submitText: 'Populate Chart'});
    } else {
      this.setState({submitText: 'Create Chart'});
      const templateValues = templates[templateName];
      document.getElementById('chart_name').value = templateValues.chart_name;
      document.getElementById('x_axis').value = templateValues.x_axis;
      document.getElementById('y_axis').value = templateValues.y_axis;
    }
  }

  render() {
    if (this.state.redirect === '/populate') {
      return <Populate {...this.props} method={this.state.method} chartId={this.state.chartId} chartURL={this.state.chartURL} options={this.state.options} chartType={this.state.chartType}/>;
    } else if (this.state.redirect === '/finish' ) {
      return <Finish data={this.state.data} chartURL={this.state.chartURL} options={this.state.options} chartType={this.state.chartType}/>;
    } else if (this.state.redirect === '/error' && this.state.errorMessage) {
      return <ErrorPage {...this.props} message={this.state.errorMessage}/>;
    } else if (this.state.redirect) {
      throw new Error('Should never get here');
    }

    let prevChartsLink = '';
    if (this.state.chartMap != null && this.state.chartMap!=='{}') {
      prevChartsLink = (<a className="test-link" data-toggle="modal" data-target="#previous-charts" style={{color: 'yellow', fontSize: 18}}
        href="#ShowPrevious" > Show your previously-created charts </a> );
    }

    return (
      <div style={{fontFamily: 'Poppins'}}>
        <div className="wrapper_centering">
          <br/><br />
          <div className="corner-ribbon top-right sticky red">Beta Testing</div>

          <div className="container_centering">
            <br />
            <img src="img/logo.png" style={{width: 55, height: 55, marginLeft: 20}} alt="logo"/>

            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-6 col-lg-6 align-items-center">
                  <div className="main_title_1">

                    <p style={{fontSize: 30, fontWeight: 'bold'}}> Start with Simple Time Series </p>
                    <p style={{fontSize: 26}}> A GroveFlow Web Chart</p>
                    <img src="img/chart_example.png" style={{width: 500}} className="example-chart-image" alt="chart example" /> <br /><br />
                    {prevChartsLink}

                  </div>
                </div>

                <div className="col-xl-5 col-lg-5 align-items-center">
                  <br /><br /><br /><br /><br /> <br />
                  <form id="form" action={this.baseURL + '/setup-chart'} method="post" onSubmit={this.onSubmit} autoComplete="off" encType="multipart/form-data">

                    <input readOnly type="hidden" id="email" name="email" value={this.userProfile.email}/>
                    <input readOnly type="hidden" id="token" name="token" value={this.userProfile.token}/>

                    <h3 className="main_question"><b>Customize your chart, {this.state.userProfile.givenName}!</b></h3>

                    <select id="template" name="template" onChange={this.onTemplateChange} style={{color: 'white', fontSize: 17, backgroundColor: 'transparent', borderStyle: 'dotted', borderColor: 'lightgray'}} defaultValue="" required>
                      <option value="" disabled hidden>Choose a template or use your own data</option>
                      <option value="template_life" style={{backgroundColor: '#1abc9c'}}>Template: Healthy Life Expectancy at Birth</option>
                      <option value="template_gdp" style={{backgroundColor: '#1abc9c'}}>Template: Economic Output Over Time</option>
                      <option value="template_temperature" style={{backgroundColor: '#1abc9c'}}>Template: Temperature Change Over Time</option>
                      <option value="datasheet" style={{backgroundColor: '#1abc9c'}}>Fill out a data sheet</option>
                      <option value="csv" style={{backgroundColor: '#1abc9c'}}>Upload a CSV</option>
                    </select>

                    <br/><br/>

                    <div className="form-group">
                      <input type="text" id="chart_name" name="chart_name" style={{WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)'}} placeholder="Chart Name" className="form-control" required />
                    </div>

                    <div className="form-group">
                      <input type="text" id="x_axis" name="x_axis" style={{WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)'}} placeholder="X Axis Name" className="form-control fl-input" required />
                    </div>

                    <div className="form-group">
                      <input type="text" id="y_axis" name="y_axis" style={{WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)'}} placeholder="Y Axis Name" className="form-control fl-input" required />
                    </div>


                    <div className="row">

                      <div className="col-9">
                        <div className="form-group radio_input">
                          <label>Line Color:</label>
                          <label className="container_radio">Red
                            <input type="radio" id="color_choice_1" name="color" value="#dc3912" required defaultChecked/>
                            <span className="checkmark"></span>
                          </label>
                          <label className="container_radio">Green
                            <input type="radio" id="color_choice_2" name="color" value="#109618" required />
                            <span className="checkmark"></span>
                          </label>

                          <label className="container_radio">Blue
                            <input type="radio" id="color_choice_3" name="color" value="#007bff" required />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div id="bottom-wizard">
                      <button id="submit_button" type="submit" className="submit">{this.state.submitText}</button>
                    </div>

                  </form>

                </div>
              </div>
            </div>
          </div>

        </div>

        <PrevCharts {...this.props} />

      </div>
    );
  }
}
