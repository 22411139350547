import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {EditPage} from './EditPage';
import Confetti from 'react-confetti';
import {Graph} from './Graph';
import {GoogleLogin} from 'react-google-login';
import {Forecast} from './Forecast';

export class Finish extends Component {
  constructor(props) {
    super(props);

    this.state = {
			data: props.data, 
			options: props.options, 
			chartURL: props.chartURL, 
			chartType: props.chartType,
			userProfile: JSON.parse(localStorage.getItem('userProfile')) || null,
		};
    this.handleEditButton=this.handleEditButton.bind(this);
    if (window.location.host.startsWith('localhost')) {
      this.baseURL = 'http://localhost:8080';
    } else if (window.location.host.startsWith('charts-testing')) {
      this.baseURL = 'https://web-charts-test.uc.r.appspot.com';
    } else {
      this.baseURL = 'https://web-charts.uc.r.appspot.com';
    }
  }

	onEditButtonLogin = (googleUser) => {
	  const token = googleUser.getAuthResponse().id_token;

	  const googleProfile = googleUser.getBasicProfile();
	  const userProfile = {
			givenName: googleProfile.getGivenName(),
	    familyName: googleProfile.getFamilyName(),
	    email: googleProfile.getEmail(),
	    imageUrl: googleProfile.getImageUrl(),
			token: token
		};
	  this.setState({userProfile: userProfile});
	  this.handleEditButton('');
	}

	onFailure = (error) => {
	  console.log(error);
	}

	handleEditButton(googleLogin) {
	  // console.log(this.state.userProfile);
	  if (this.state.userProfile) {
	    const formBody = new URLSearchParams();
	    formBody.append('url', this.state.chartURL);
	    formBody.append('token', this.state.userProfile.token);
	    formBody.append('email', this.state.userProfile.email);

	    const requestOptions = {
	      method: 'POST',
	      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
	      body: formBody,
	    };
	    fetch(this.baseURL+'/verify-edit-perms', requestOptions)
	        .then((res)=>res.json())
	        .then((results)=>{
	          if (results.verified===true) {
							localStorage.setItem('userProfile', JSON.stringify(this.state.userProfile));
	            this.setState({redirect: '/edit'});
	          } else {
							//alert('You are not authorized to edit this chart.');
							googleLogin.onClick();
	          }
	        });
	  } else {
	    googleLogin.onClick();
	  }
	}

	render() {
		// console.log(this.state);

	  if (this.state.redirect) {
	    return <EditPage userProfile={this.state.userProfile} data={this.state.data} options={this.state.options} chartURL={this.state.chartURL} chartType={this.state.chartType}/>;
	  }
	  return (

	    <div className="wrapper_centering" style={{fontFamily: 'Poppins'}}>
	      <br/><br />
	      <div className="corner-ribbon top-right sticky red">Beta Testing</div>
	      <Confetti numberOfPieces={700} recycle={false}/>

	      <div className="container_centering">
	        <br/>
	        <img src="img/logo.png" style={{width: 55, height: 55, marginLeft: 20}} alt="logo"/>

	        <div className="container">
	          <div className="row justify-content-between">

	            <div className="main_title_1">
	              <h3>Congratulations!</h3>
	              <p style={{fontSize: 26, fontWeight: 'bold'}}>You created a live GroveFlow Web Chart<br /><br />
															What do you think? Let us know: <a style={{color: 'yellow'}} href={'mailto:hello@groveflow.com'}>hello@groveflow.com</a></p>
	            </div>

	            <div id="chart_div" className="live-chart-preview">
	              <Graph data={this.state.data} options={this.state.options} chartType={this.state.chartType}/>
	            </div>


							<div>

								{/* available in private beta */}
								{this.state.userProfile && this.state.data ? <Forecast data={this.state.data} chartURL={this.state.chartURL} options={this.state.options} chartType={this.state.chartType} userProfile={this.state.userProfile}/> : null}
								
							</div>

	            <div className="main_title_1">
	              <br /><br />
	              {/* <img src="img/chart_example.png" style={{ width: 400, marginLeft: 100}} /><br /><br />*/}
	              <p style={{fontSize: 26}}> View and share this chart: <br />
	                <a style={{fontSize: 26, color: 'yellow'}} href={'/view-'+this.state.chartURL}>charts.groveflow.com/view-{this.state.chartURL}</a> </p>

	              <table style={{width: '120%', marginTop: '10px'}}><tr><td>

	                <a style={{fontSize: 22, color: 'yellow'}} href="#Copy">
	                  <svg width="1.1em" height="1.1em" viewBox="0 0 16 16" className="bi bi-clipboard-data" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
	                    <path fillRule="evenodd" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
	                    <path fillRule="evenodd" d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
	                    <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z"/>
	                  </svg>
	                  <CopyToClipboard text={'charts.groveflow.com/view-'+this.state.chartURL}>
	                    <a href="#CopyLink" style={{color: 'yellow'}}> Copy Link </a>
	                  </CopyToClipboard>
	                </a>
	              </td><td>

	                <a style={{fontSize: 22, color: 'yellow'}} href="#Edit">
	                  <GoogleLogin
	                    clientId="926404384090-mu63593muenf42d8r0p3rvsk8sdk7gq6.apps.googleusercontent.com"
	                    render={(renderProps) => (
	                      <span >
	                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
	                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
	                          <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
	                        </svg>
	                        <span id = 'editText' onClick={()=>{
	                          this.handleEditButton(renderProps);
	                        }} > Edit This Chart</span>
	                      </span>
	                    )}
	                    buttonText="Login"
	                    onSuccess={this.onEditButtonLogin}
	                    onFailure={this.onFailure}

	                    // cookiePolicy={'single_host_origin'}
	                  />
	                </a>
	              </td><td>
	                <a style={{fontSize: 22, color: 'yellow'}} href="/">
	                  <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
	                    <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
	                    <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
	                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
	                  </svg>
	                  {' Create New Chart'}
	                </a>

	              </td></tr></table>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  );
	}
}
