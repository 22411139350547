import React, {Component} from 'react';
import {Graph} from './Graph';

export class ForecastGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
        options:props.options, 
        userProfile:props.userProfile, 
        chartURL:props.chartURL, 
        chartType:props.chartType
    };

    //this.handleChange = this.handleChange.bind(this);

    this.getForecast = this.getForecast.bind(this);

    if (window.location.host.startsWith('localhost')) {
      this.baseURL = 'http://localhost:8080';
    } else if (window.location.host.startsWith('charts-testing')) {
      this.baseURL = 'https://web-charts-test.uc.r.appspot.com';
    } else {
      this.baseURL = 'https://web-charts.uc.r.appspot.com';
    }
  }


  checkBetaAuth() {
    const formBody = new URLSearchParams();
    formBody.append('email', this.state.userProfile.email);
    formBody.append('token', this.state.userProfile.token);
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: formBody,
    };
    fetch(this.baseURL+'/check-beta-auth', requestOptions)
        .then((res)=>res.json())
        .then((results)=>{
          // console.log(results);
          this.setState({beta: results.beta});
          if (results.beta===true) {
            this.getForecast();
          }
        });
  }

  componentDidMount() {
    this.checkBetaAuth();
  }

  getForecast() {
    const formBody = new URLSearchParams();
    formBody.append('url', this.state.chartURL);
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: formBody,
    };
    fetch(this.baseURL+'/get-forecast-results', requestOptions)
        .then((res)=>res.json())
        .then((results)=>{
          console.log(results);
          if (!results.error) {
            this.setState({'p10': results.p10, 'p50': results.p50, 'p90': results.p90});
          } else {
            console.log('Forecast Incomplete');
          }
        });
  }

  render() {
    const p10options ={...this.state.options};
    const p50options = {...this.state.options};
    const p90options={...this.state.options};
    p10options.title = this.state.options.title+': Lower-Bound Forecast (10% range)';
    p50options.title = this.state.options.title+': Middle-Bound Forecast (50% range)';
    p90options.title = this.state.options.title+': Upper-Bound Forecast (90% range)';
    
    if (!this.state.beta) {
      return null; // user not in private beta, or not authenticated
    } else if (this.state.p10) {
      return (
        <div>
          <br></br><br></br>
          <div className="main_title_1" style={{fontSize: 26, fontWeight: 'bold'}}>
						Machine-Generated Forecast Predictions 
					</div><br></br>
          <div id="chart_div" className="live-chart-preview">
            {this.state.p10 ? <Graph data={this.state.p10} options={p10options} chartType={this.state.chartType}/> : null}
            <br></br><br></br>
            {this.state.p50 ? <Graph data={this.state.p50} options={p50options} chartType = {this.state.chartType}/>: null}
            <br></br><br></br>
            {this.state.p90 ? <Graph data = {this.state.p90} options = {p90options} chartType = {this.state.chartType}/>:null}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
