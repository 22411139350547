import React, {Component} from 'react';
import {Graph} from './Graph';

export class EditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartURL: props.chartURL, 
      data: props.data, 
      options: props.options, 
      chartType: props.chartType, 
      userProfile: props.userProfile
    };
    if (window.location.host.startsWith('localhost')) {
      this.baseURL = 'http://localhost:8080';
    } else if (window.location.host.startsWith('charts-testing')) {
      this.baseURL = 'https://web-charts-test.uc.r.appspot.com';
    } else {
      this.baseURL = 'https://web-charts.uc.r.appspot.com';
    }
  }

  render() {
    // console.log(this.state);
    // if done button pressed, redirect back to finish
    return (

      <div className="wrapper_centering">
        <br/><br />

        <div className="container_centering">
          <img src="img/logo.png" alt="logo" style={{width: 55, height: 55, marginLeft: 20}} />

          <div className="container">
            <div className="row justify-content-between">

              <div className="main_title_1">
                <h3>GroveFlow Web Charts</h3>
                <p style={{fontSize: 26, fontWeight: 'bold'}}>Need to edit your chart? Enter new data into the table!</p>
              </div>

              <div id="chart_div" className="live-chart-preview">
                <Graph data={this.state.data} options={this.state.options} edit="true" chartURL={this.state.chartURL} chartType={this.state.chartType}/>
              </div>
              
            </div>

          </div>
        </div>
      </div>
    );
  }
}
