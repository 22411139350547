import React, {Component} from 'react';
import {ErrorPage} from './ErrorPage';

export class PrevCharts extends Component {
  constructor(props) {
    super(props);
    // console.log("Prev charts user : " + this.props.userProfile);
    this.state = {
      userProfile: null,
      chartMap: null,
      redirect: null,
      errorMessage: null,
    };

    // i couldn't login, userprofile was undefined and it gave some errors, so i set state.userprofile in different way
    if ( typeof(localStorage.getItem('userProfile')) === 'undefined') {
      this.state.userProfile = null;
    } else {
      this.state.userProfile = JSON.parse(localStorage.getItem('userProfile'));
    }

    if (window.location.host.startsWith('localhost')) {
      this.baseURL = 'http://localhost:8080';
    } else if (window.location.host.startsWith('charts-testing')) {
      this.baseURL = 'https://web-charts-test.uc.r.appspot.com';
    } else {
      this.baseURL = 'https://web-charts.uc.r.appspot.com';
    }

    this.userProfile = { // default test profile
      givenName: 'Test',
      familyName: 'User',
      email: 'test@user.com',
      imageUrl: '',
      token: '',
    };

    if (this.props.userProfile !== null) {
      this.state.userProfile = this.props.userProfile;
      localStorage.setItem('userProfile', JSON.stringify(this.props.userProfile));
      this.userProfile = this.props.userProfile;
    } else if (this.state.userProfile !== null) {
      this.userProfile = this.state.userProfile;
    } else {
      this.state.userProfile = this.userProfile;
    }
    console.log('this.userProfile.token:', this.userProfile.token);
  }

  componentDidMount() {
    fetch(this.baseURL + '/get-charts-by-email', {
      method: 'POST',
      body: new URLSearchParams({
        'email': this.state.userProfile.email,
        'token': this.state.userProfile.token,
      }),
    }).then((res) => {
      return res.json();
    }).then((body) => {
      this.setState({chartMap: JSON.stringify(body.charts)});
    }).catch((err) => {
      console.log(err.message);
      this.setState({
        redirect: '/error',
        errorMessage: err.message,
      });
    });
  }

  render() {
    if (this.state.redirect === '/error' && this.state.errorMessage) {
      return <ErrorPage {...this.props} message={this.state.errorMessage}/>;
    } else if (this.state.redirect) {
      throw new Error('Should never get here');
    }

    const prevCharts = [];

    // console.log("this.state.chartMap:"+this.state.chartMap);

    if (this.state.chartMap != null) {
      if (this.state.chartMap==='{}') {
        prevCharts.push('You don\'t have any previously-created charts.');
      } else {
        const chartsList = JSON.parse(this.state.chartMap);
        for (const chart of chartsList) {
          for (const key in chart) {
            prevCharts.push(<p key={chart[key]}><a style={{color: 'darkgreen'}} href={'view-'+chart[key]} target="_blank" rel="noopener noreferrer">{key}</a></p>);
          }
        }
      }
    }

    return (

      <div className="modal fade" id="previous-charts" tabIndex="-1" role="dialog" aria-labelledby="previousChartsLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="previousChartsLabel">Your Previously-Created Charts</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body">
              {prevCharts}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn_1" data-dismiss="modal">Close</button>
            </div>
          </div>

        </div>

      </div>

    );
  }
}
